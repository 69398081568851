import classnames from "classnames";
export var ButtonTheme = {
    variant: {
        primary: classnames("border border-solid border-grayBlack-400 cursor-pointer text-center bg-grayBlack-400", "text-white hover:bg-newGray-700 focus:bg-grayBlack-400 disabled:bg-newGray-500 disabled:border-newGray-500"),
        "primary-yellow": classnames("border border-solid border-primaryYellow-400 cursor-pointer text-center bg-primaryYellow-400", "text-primaryGray-900 hover:bg-primaryYellow-600 disabled:bg-grayAsh-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        blue: classnames("border border-solid border-dodgerBlue-400 cursor-pointer text-center", "text-white bg-dodgerBlue-400 hover:bg-dodgerBlue-300 disabled:bg-grayAsh-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        ghost: classnames("border text-primaryBlack-400 border-solid border-primaryBlack-400 cursor-pointer text-center text-white", "disabled:border-grayAsh-400 hover:bg-primaryGray-300 focus:bg-primaryGray-300", "disabled:text-grayAsh-400 disabled:bg-transparent"),
        "gray-ghost": classnames("border text-primaryBlack-400 border-solid border-primaryGray-600 cursor-pointer text-center", "disabled:border-grayAsh-400 hover:bg-primaryGray-300 focus:bg-primaryGray-300", "disabled:text-grayAsh-400 disabled:bg-transparent"),
        danger: classnames("border border-solid border-red-300 bg-red-300 cursor-pointer text-center text-white ", "hover:bg-red-400 focus:bg-red-400 disabled:text-grayAsh-400 disabled:bg-grayAsh-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        "danger-ghost": classnames("border border-solid border-primaryBlack-400 cursor-pointer text-center text-red-300 ", "hover:text-red-400 hover:border-red-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        white: classnames("border border-solid border-grayAsh-500 cursor-pointer text-center text-white", "hover:bg-grayAsh-200 focus:bg-grayAsh-200", "disabled:text-grayAsh-500 disabled:bg-grayAsh-200 disabled:border-grayAsh-400"),
        "white-danger": classnames("border border-solid border-white bg-red-400 cursor-pointer text-center text-white", "hover:bg-red-500 focus:bg-red-500 disabled:text-grayAsh-400 disabled:bg-grayAsh-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        "transparent-danger": classnames("text-red-300 text-center text-white"),
        success: classnames("border border-solid border-emerald-400 bg-emerald-400 cursor-pointer text-center text-black", "hover:bg-emerald-500 focus:bg-emerald-500 disabled:text-grayAsh-400 disabled:bg-grayAsh-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        warning: classnames("border border-solid border-amber-400 bg-amber-400 cursor-pointer text-center text-black", "hover:bg-amber-500 focus:bg-amber-500 disabled:text-grayAsh-400 disabled:bg-grayAsh-400 disabled:border-grayAsh-400", "disabled:text-grayAsh-500"),
        transparent: "border-solid text-primaryBlack-400 hover:text-black focus:text-black disabled:text-grayAsh-400",
        gradient: classnames("bg-gradient-to-r from-pink-500 to-yellow-500", "cursor-pointer text-center text-white"),
        link: "border-none text-primaryBlack-400 underline disabled:text-dodgerBlue-600"
    },
    size: {
        xs: "py-1 text-xs",
        sm: "py-1.5 text-sm",
        md: "py-1.5 text-sm",
        lg: "py-2.5 text-base",
        xl: "py-3.5 text-base"
    },
    iconSize: {
        xs: {
            width: "3",
            height: "3"
        },
        sm: {
            width: "4",
            height: "4"
        },
        md: {
            width: "5",
            height: "5"
        },
        lg: {
            width: "4.5",
            height: "4.5"
        },
        xl: {
            width: "6.5",
            height: "6.5"
        }
    }
};
